
import { Component, Vue, Prop } from "vue-property-decorator";
import { IUpdateVersion, updateVersions } from "@/api/version";

type IVersion = IResponse.Version.IVersion;
type IUserGroup = IResponse.GrayRelease.IUserGroup;

interface IUploadForm {
  CFBundleIdentifier: string;
  CFBundleVersion: string;
  icon: string;
  requisite: number;
  src: string;
  type: string;
  versions_number: string;
  App_Store: string;
  description: string;
  releaseType: number;
  userGroupId: string;
}

@Component({})
export default class ModalUpdateVersion extends Vue {
  @Prop({ default: [] }) public readonly userGroupList!: IUserGroup[];
  public version: IVersion | null = null;
  public visible: boolean = false;
  public form: any;
  public expirationDate: string = "";
  public formItemLayout: object = {
    labelCol: { span: 7 },
    wrapperCol: { span: 16 },
  };
  public allUserOptions: Array<{ title: string; value: string }> = [];
  public isGrayRelease: boolean = false;

  public beforeCreate(): void {
    this.form = this.$form.createForm(this);
  }

  public changeVisible(version?: IVersion): void {
    this.visible = !this.visible;
    if (this.visible) {
      this.form = this.$form.createForm(this);
      if (version) {
        this.$nextTick(() => {
          this.initForm(version);
        });
      }
    } else {
      this.form.resetFields();
    }
  }
  public initForm(version: IVersion): void {
    this.form.setFieldsValue({
      src: version.src,
      icon: version.icon,
      versions_number: version.versions_number,
      CFBundleIdentifier: version.CFBundleIdentifier,
      CFBundleVersion: version.CFBundleVersion,
      requisite: !!version.requisite,
      type: version.type,
      description: version.description,
      App_Store: version.App_Store,
      releaseType: version.userGroupId ? 1 : 0,
      userGroupId: version.userGroupId,
    });
    this.expirationDate = version.ExpirationDate;
    this.isGrayRelease = !!version.userGroupId;
  }
  public submit(): void {
    this.form.validateFields(async (err: object, values: IUploadForm) => {
      if (!err) {
        try {
          const request: IUpdateVersion = {
            id: this.version!.id,
            ExpirationDate: this.expirationDate,
            ...values,
            userGroupId:
              values.userGroupId && values.releaseType === 1
                ? values.userGroupId
                : null,
          };
          const response = await updateVersions(request);
          if (!response.status) {
            this.$message.error(response.message);
            return;
          }
          this.$message.success("Update success!");
          this.$emit("refresh");
          this.changeVisible();
        } catch (err) {
          this.$message.error(err as string);
        }
      }
    });
  }
  public onSelectReleaseType(value: number) {
    this.isGrayRelease = !!value;
  }

  public get platform(): string {
    // 判断APP类型
    if (!this.version) {
      return "";
    }
    const src = this.version.src;
    const platform = this.version.src.substring(src.length - 3);
    if (platform === "apk") {
      return "Android";
    } else {
      return "Ios";
    }
  }
  public get userGroupOptions(): Array<{ title: string; value: string }> {
    return this.userGroupList.map((group) => {
      return { title: group.name, value: group.id };
    });
  }
}
