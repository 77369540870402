
import { Component, Vue, Prop } from "vue-property-decorator";
import { ICreateAppVersion, createAppItems } from "@/api/version";
import config from "@/config";
import storage from "@/utils/storage";

const getToken = () => {
  return storage.localGet("version_user_info").token;
};

type IUserGroup = IResponse.GrayRelease.IUserGroup;
interface IUploadForm {
  CFBundleIdentifier: string;
  CFBundleVersion: string;
  icon: string;
  requisite: number;
  src: string;
  type: string;
  versions_number: string;
  App_Store: string;
  description: string;
  releaseType: number;
  userGroupId: string;
}

@Component({})
export default class ModalUpload extends Vue {
  @Prop({ default: [] }) public readonly userGroupList!: IUserGroup[];
  public id: string = "";
  public type: string = "";
  public visible: boolean = false;
  public bodyStyle: object = { width: "800px", height: "280px" };
  public fileList: File[] = [];
  public loading: boolean = false;
  public buttonLoading: boolean = false;
  public form: any;
  public isShowForm: boolean = false;
  public appType: string = "";
  public expirationDate: string = "";
  public apiBaseUrl: string = config.apiBaseUrl;
  public token: string = "";
  public formItemLayout: object = {
    labelCol: { span: 7 },
    wrapperCol: { span: 16 },
  };
  public allUserOptions: Array<{ title: string; value: string }> = [];
  public isGrayRelease: boolean = false;

  public beforeCreate(): void {
    this.form = this.$form.createForm(this);
  }
  public changeVisible(): void {
    this.token = getToken();
    this.visible = !this.visible;
    this.form.resetFields();
    this.fileList = [];
    this.isShowForm = false;
    this.appType = "";
    this.loading = false;
    this.isGrayRelease = false;
  }
  public removeFile(file: File): void {
    this.loading = false;
    const index = this.fileList.indexOf(file);
    const newFileList = this.fileList.slice();
    newFileList.splice(index, 1);
    this.fileList = newFileList;
  }
  public beforeUpload(file: File): boolean {
    return this.checkFile(file);
  }
  public async onUpload(info: any): Promise<void> {
    if (this.fileList.length) {
      this.loading = true;
    }
    const fileList = [...info.fileList];
    this.fileList = fileList.slice(-1);
    const status = info.file.status;
    if (status === "done") {
      const responseData = info.file.response.data;
      this.form.setFieldsValue({
        CFBundleIdentifier: responseData.CFBundleIdentifier,
        CFBundleVersion: responseData.CFBundleVersion,
        icon: responseData.icon,
        src: responseData.src,
        versions_number: responseData.versions_number,
        package_size: responseData.package_size,
      });
      this.expirationDate = responseData.ExpirationDate;
      this.isShowForm = true;
      this.$message.success(`${info.file.name} file uploaded successfully.`);
      this.loading = false;
    } else if (status === "error") {
      this.$message.error(`${info.file.name} file upload failed.`);
      this.loading = false;
    }
  }
  public uploadData(file: File): object {
    return {
      items_id: this.id,
    };
  }
  public checkFile(file: File): boolean {
    const fileName = file.name;
    const fileType = fileName.substring(fileName.length - 3);
    this.loading = false;
    if (this.type === "1") {
      if (fileType !== "apk") {
        this.$message.error("Must upload apk file");
        this.fileList = [];
        return false;
      } else {
        return true;
      }
    } else if (this.type === "0") {
      if (fileType !== "ipa") {
        this.$message.error("Must upload ipa file");
        this.fileList = [];
        return false;
      } else {
        return true;
      }
    } else if (this.type === "2") {
      if (fileType !== "dmg") {
        this.$message.error("Must upload dmg file");
        this.fileList = [];
        return false;
      } else {
        return true;
      }
    } else if (this.type === "3") {
      if (fileType !== "exe") {
        this.$message.error("Must upload exe file");
        this.fileList = [];
        return false;
      } else {
        return true;
      }
    } else {
      this.fileList = [];
      return false
    }
  }
  public submit(): void {
    this.buttonLoading = true;
    this.form.validateFields(async (err: object, values: IUploadForm) => {
      if (!err) {
        try {
          const request: ICreateAppVersion = {
            items_id: this.id,
            ExpirationDate: this.expirationDate,
            ...values,
            userGroupId:
              values.userGroupId && values.releaseType === 1
                ? values.userGroupId
                : null,
          };
          const response = await createAppItems(request);
          if (!response.status) {
            this.$message.error(response.message);
            this.buttonLoading = false;
            return;
          }
          this.$message.success("Upload success!");
          this.$emit("refresh");
          this.changeVisible();
          this.buttonLoading = false;
        } catch (err) {
          this.$message.error(err as string);
          this.buttonLoading = false;
        }
      } else {
        this.buttonLoading = false;
      }
    });
  }
  public typeChange(value: string): void {
    this.appType = value;
  }
  public onSelectReleaseType(value: number) {
    this.isGrayRelease = !!value;
  }

  public get userGroupOptions(): Array<{ title: string; value: string }> {
    return this.userGroupList.map((group) => {
      return { title: group.name, value: group.id };
    });
  }
}
