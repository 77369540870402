
import { Component, Vue, Prop } from "vue-property-decorator";
import { getAppItemsDetail } from "@/api/items";
import { upgrade } from "@/api/version";
import ModalUpload from "../upload/ModalUpload.vue";
import ModalShortUrl from "./shortUrl/ModalShortUrl.vue";
import config from "@/config";
import VueQr from "vue-qr";
import moment from "moment";

type IAppItems = IResponse.AppItems.IItem;
type IVersion = IResponse.Version.IVersion;
type IUserGroup = IResponse.GrayRelease.IUserGroup;

@Component({
  components: {
    ModalUpload,
    ModalShortUrl,
    VueQr,
  },
})
export default class AppInfo extends Vue {
  @Prop({ default: [] }) public readonly userGroupList!: IUserGroup[];
  public $refs!: {
    ModalUploadRef: ModalUpload;
    ModalShortUrlRef: ModalShortUrl;
  };
  public appItem: IAppItems | null = null;
  public newVersionDetail: IVersion | null = null;
  public apiBaseUrl: string = config.apiBaseUrl;
  public preViewHref: string = "";
  public preViewUrl: string = "";

  public created() {
    this.initData();
    this.initNewVersion();
  }
  public async initData(): Promise<void> {
    const id = this.$route.query.id as string;
    const response = await getAppItemsDetail(id);
    this.appItem = response.data;
    if (!this.appItem.short_url) {
      return;
    }
    const link = this.$router.resolve({ path: "/" + this.appItem.short_url });
    this.preViewHref = link.href;
    this.preViewUrl = window.location.origin + "/" + link.href;
  }
  public async initNewVersion(): Promise<void> {
    const id = this.$route.query.id as string;
    const response = await upgrade(id);
    if (!response.status) {
      this.$message.error(response.message);
    }
    if (response.data.length === 0) {
      return;
    }
    this.newVersionDetail = response.data[0];
  }
  public goToPreview(): void {
    window.open(this.preViewHref, "_blank");
  }
  public showUpdate(): void {
    this.$refs.ModalUploadRef.changeVisible();
    this.$refs.ModalUploadRef.id = this.appItem!.id;
    this.$refs.ModalUploadRef.type = this.appItem!.type;
  }
  public showShortUrl(): void {
    this.$refs.ModalShortUrlRef.changeVisible();
    this.$refs.ModalShortUrlRef.itemId = this.appItem!.id;
    this.$refs.ModalShortUrlRef.shortUrl = this.appItem!.short_url;
  }
  public refresh(): void {
    this.$emit("refresh");
    this.initData();
    this.initNewVersion();
  }
  public convertTime(time: string): string {
    if (!time) {
      return "";
    }
    return moment(time).format("YYYY-MM-DD HH:mm:ss");
  }

  public get isExpired(): boolean {
    if (!this.newVersionDetail) {
      return false;
    }
    const expirationDate = new Date(this.newVersionDetail.ExpirationDate);
    return expirationDate < new Date();
  }
}
