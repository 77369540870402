
import { Component, Vue } from 'vue-property-decorator';
import { getVersionDownloadCount, IGetVersionDownloadCount } from '@/api/version';
import moment from 'moment';
import viser from 'viser-vue';

type IDownloadCount = IResponse.Version.IGetVersionDownloadCount;

Vue.use(viser)

@Component({})
export default class DownloadChart extends Vue {
  public data: IDownloadCount[] = []
  public scale = [{
      dataKey: 'value',
      alias: '统计次数',
      min: 0,
    }, {
      dataKey: 'createdAt',
      min: 0,
      type: 'cat',
    }];
  public height: number = 400

  public created() {
    this.initData();
  }
  public initData(): void {
    this.getVersionDownloadCountEvent();
  }
  public async getVersionDownloadCountEvent(startTime?: string, endTime?: string ): Promise<void> {
    const id = this.$route.query.id as string;
    const params: IGetVersionDownloadCount = {
      items_id: id,
      start_time: startTime,
      end_time: endTime,
    }
    const response = await getVersionDownloadCount(params);
    this.data = response.list;
  }
  public dateChange(date: moment.Moment, dateString: string[]) {
    this.getVersionDownloadCountEvent(dateString[0], dateString[1]);
  }
  public disabledDate(current: moment.Moment) {
      return current && current >= moment().endOf('day');
  }
}
