
import { Component, Vue, Prop } from "vue-property-decorator";
import { getVersions, deleteVersions } from "@/api/version";
import ModalUpdateVersion from "./updateVersion/ModalUpdateVersion.vue";
import ModalDeviceList from "./deviceInfo/ModalDeviceList.vue";
import moment from "moment";

type IVersion = IResponse.Version.IVersion;
type IUserGroup = IResponse.GrayRelease.IUserGroup;

const columns = [
  {
    title: "Version number",
    dataIndex: "versions_number",
    width: 130,
  },
  {
    title: "Build",
    dataIndex: "CFBundleVersion",
    width: 80,
  },
  {
    title: "Download count",
    dataIndex: "download_count",
    width: 130,
  },
  {
    title: "Package size",
    scopedSlots: { customRender: "packageSize" },
    dataIndex: "package_size",
    width: 130,
  },
  {
    title: "Released time",
    scopedSlots: { customRender: "releaseTime" },
    dataIndex: "create_time",
    width: 150,
  },
  {
    title: "Update time",
    scopedSlots: { customRender: "updateTime" },
    dataIndex: "update_time",
    width: 150,
  },
  {
    title: "User group",
    scopedSlots: { customRender: "userGroup" },
    dataIndex: "userGroupId",
  },
  {
    title: "Description",
    dataIndex: "description",
    ellipsis: true,
  },
  {
    title: "Action",
    scopedSlots: { customRender: "action" },
    dataIndex: "id",
    width: 330,
  },
];

@Component({
  components: {
    ModalUpdateVersion,
    ModalDeviceList,
  },
})
export default class AppVersions extends Vue {
  public $refs!: {
    ModalUpdateVersionRef: ModalUpdateVersion;
    ModalDeviceListRef: ModalDeviceList;
  };
  @Prop({ default: [] }) public readonly userGroupList!: IUserGroup[];
  public versions: IVersion[] = [];
  public columns: object[] = columns;

  public created(): void {
    this.initVersions();
  }
  public convertTime(time: string): string {
    if (time) {
      return moment(time).format("YYYY/MM/DD HH:mm");
    } else {
      return "-";
    }
  }
  public async initVersions(): Promise<void> {
    const id = this.$route.query.id as string;
    const response = await getVersions(id);
    if (!response.status) {
      this.$message.error(response.message);
      return;
    }
    this.versions = response.list;
  }
  public async deleteVersion(version: IVersion): Promise<void> {
    const fileNameArray = version.src.split("/");
    const fileName = fileNameArray[fileNameArray.length - 1];
    const response = await deleteVersions(version.id, version.items_id, fileName);
    if (!response.status) {
      this.$message.error(response.message);
      return;
    }
    this.$message.success("Delete success!");
    this.initVersions();
  }
  public showUpdateVersion(version: IVersion): void {
    this.$refs.ModalUpdateVersionRef.version = version;
    this.$refs.ModalUpdateVersionRef.changeVisible(version);
  }
  public showDeviceInfo(version: IVersion): void {
    this.$refs.ModalDeviceListRef.changeVisible(version);
  }
  public goToPreview(version: IVersion): void {
    const link = this.$router.resolve({ path: "/" + version.id });
    window.open(link.href, "_blank");
  }
  public findGroupName(id: string): string {
    const userGroupName = this.userGroupList.find((userGroup) => userGroup.id === id)?.name;
    if (userGroupName) {
      return userGroupName;
    } else {
      return "-";
    }
  }
}
