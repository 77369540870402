
import { Component, Vue } from 'vue-property-decorator';
import { getDeviceList } from '@/api/version';

type IDeviceInfo = IResponse.Version.IGetDeviceInfo;
type IVersion = IResponse.Version.IVersion;

const columns = [
    {
        title: "User name",
        dataIndex: "user_name",
        width: 130
    },
    {
        title: "Device name",
        dataIndex: "device_name",
        width: 130
    },
    {
        title: "Device version",
        dataIndex: "device_version",
        width: 130
    }
]

@Component({})
export default class ModalDeviceList extends Vue {
    public visible: boolean = false
    public deviceList: IDeviceInfo[] = []
    public columns: object[] = columns;

    public changeVisible(version: IVersion): void {
        this.visible = !this.visible;
        if (this.visible) {
            this.getDeviceList(version.id);
        }
    }
    public async getDeviceList(versionId: string): Promise<void> {
        const response = await getDeviceList(versionId);
        this.deviceList = response.list;
    }
}
