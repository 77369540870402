
import { Component, Vue } from "vue-property-decorator";
import AppInfo from "./appInfo/AppInfo.vue";
import AppVersions from "./appVersions/AppVersions.vue";
import DownloadChart from "./downloadChart/DownloadChart.vue";
import UserGroup from "./userGroup/UserGroup.vue";
import { getItemUserGroupList, getAllUnitUserList } from "@/api/grayRelease";

type IUnitUser = IResponse.GrayRelease.IUnitUser;
type IUserGroup = IResponse.GrayRelease.IUserGroup;

@Component({
  components: {
    AppInfo,
    AppVersions,
    DownloadChart,
    UserGroup,
  },
})
export default class AppDetail extends Vue {
  public $refs!: {
    AppVersionsRef: AppVersions;
  };
  public allUserList: IUnitUser[] = [];
  public userGroupList: IUserGroup[] = [];

  public created() {
    this.initAllUserList();
    this.initUserGroupList();
  }

  public refresh(): void {
    this.$refs.AppVersionsRef.initVersions();
  }
  public toBack(): void {
    this.$router.go(-1);
  }
  public async initAllUserList() {
    const response = await getAllUnitUserList();
    if (!response.status) {
      this.$message.error(response.message);
      return;
    }
    this.allUserList = this.generateList(response.data);
  }
  public generateList(data: IUnitUser[]) {
    data.map((node) => {
      const key = node.id;
      node.scopedSlots = { title: "name" };
      if (node.childs) {
        this.generateList(node.childs);
      }
    });
    return data;
  }
  public async initUserGroupList() {
    const id = this.$route.query.id as string;
    const response = await getItemUserGroupList(id);
    if (!response.status) {
      this.$message.error(response.message);
      return;
    }
    this.userGroupList = response.data;
  }
}
