
import { Component, Vue } from 'vue-property-decorator';
import { setShortUrl } from '@/api/items';

interface IForm {
    shortUrl: string
}

@Component({})
export default class ModalShortUrl extends Vue {
    public itemId: string = "";
    public shortUrl: string = "";
    public visible: boolean = false;
    public form: any;
    public formItemLayout: object = {
      labelCol: { span: 7 },
      wrapperCol: { span: 16 },
    }

    public beforeCreate(): void {
        this.form = this.$form.createForm(this);
    }

    public changeVisible(): void {
        this.visible = !this.visible;
        this.$nextTick(() => {
            this.form.resetFields();
            if (!this.shortUrl || !this.visible) {
                return;
            }
            this.initForm(this.shortUrl);
        })
    }
    public initForm(shortUrl: string): void {
        this.form.setFieldsValue({ shortUrl });
    }
    public submit(): void {
        this.form.validateFields(async (err: object, values: IForm) => {
            if (!err) {
                try {
                    const response = await setShortUrl(this.itemId, values.shortUrl)
                    if (!response.status) {
                        this.$message.error(response.message);
                        return;
                    }
                    this.$message.success('Success!');
                    this.$emit('refresh')
                    this.changeVisible();
                } catch (err) {
                    this.$message.error(err);
                }
            }
        })
    }
}
