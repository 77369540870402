
import { Component, Vue, Prop } from "vue-property-decorator";
import { deleteItemUserGroup } from "@/api/grayRelease";
import UserGroupModal from "./userGroupModal/UserGroupModal.vue";
import moment from "moment";

type IUnitUser = IResponse.GrayRelease.IUnitUser;
type IUserGroup = IResponse.GrayRelease.IUserGroup;

const columns = [
  {
    title: "Name",
    dataIndex: "name",
  },
  {
    title: "Created time",
    scopedSlots: { customRender: "createdTime" },
    dataIndex: "createdAt",
  },
  {
    title: "Updated time",
    scopedSlots: { customRender: "updatedTime" },
    dataIndex: "updatedAt",
  },
  {
    title: "Action",
    scopedSlots: { customRender: "action" },
    dataIndex: "id",
    width: 330,
  },
];

@Component({
  components: { UserGroupModal },
})
export default class UserGroup extends Vue {
  public $refs!: {
    UserGroupModalRef: UserGroupModal;
  };
  @Prop({ default: [] }) public readonly allUserList!: IUnitUser[];
  @Prop({ default: [] }) public readonly userGroupList!: IUserGroup[];
  public columns: object[] = columns;

  public onShowUserGroupModal(userGroup?: IUserGroup) {
    if (userGroup) {
      this.$refs.UserGroupModalRef.onChangeVisible({ id: userGroup.id, name: userGroup.name });
    } else {
      this.$refs.UserGroupModalRef.onChangeVisible();
    }
  }
  public async onDeleteUserGroup(id: string) {
    const response = await deleteItemUserGroup(id);
    if (!response.status) {
      this.$message.error(response.message);
      return;
    }
    this.$message.success("Delete success!");
    this.$emit("refresh");
  }
  public convertTime(time: string): string {
    if (time) {
      return moment(time).format("YYYY/MM/DD HH:mm");
    } else {
      return "-";
    }
  }
}
