import apiRequest from "./libs/apiRequest";

// 获取用户组列表
export const getItemUserGroupList = (id: string): Promise<IResponse.GrayRelease.IGetUserGroupList> => {
  return apiRequest.get({
    url: "/getItemUserGroupList",
    params: { id },
  });
};

// 获取用户组的用户列表
export const getItemUserGroupUserList = (id: string): Promise<IResponse.GrayRelease.IGetItemUserGroupUserList> => {
  return apiRequest.get({
    url: "/getItemUserGroupUserList",
    params: { id },
  });
};

// 新增用户组
export const addItemUserGroup = ({
  itemId,
  name,
  itemGroupUser,
}: {
  itemId: string;
  name: string;
  itemGroupUser: string[];
}): Promise<IResponse.IResponseMessage> => {
  return apiRequest.post({
    url: "/addItemUserGroup",
    data: { itemId, name, itemGroupUser },
  });
};

// 更新用户组
export const updateItemUserGroup = ({
  id,
  itemId,
  name,
  itemGroupUser,
}: {
  id: string;
  itemId: string;
  name: string;
  itemGroupUser: string[];
}): Promise<IResponse.IResponseMessage> => {
  return apiRequest.post({
    url: "/updateItemUserGroup",
    data: { id, itemId, name, itemGroupUser },
  });
};

// 删除用户组
export const deleteItemUserGroup = (id: string): Promise<IResponse.IResponseMessage> => {
  return apiRequest.post({
    url: "/deleteItemUserGroup",
    data: { id },
  });
};

// 获取用户组织架构
export const getAllUnitUserList = (): Promise<IResponse.GrayRelease.IGetAllUnitUserList> => {
  return apiRequest.get({
    url: "/getAllUnitUserList",
  });
};
