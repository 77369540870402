
import { Component, Prop, Vue } from "vue-property-decorator";
import {
  addItemUserGroup,
  updateItemUserGroup,
  getItemUserGroupUserList,
} from "@/api/grayRelease";
import config from "@/config";
import storage from "@/utils/storage";

type IUnitUser = IResponse.GrayRelease.IUnitUser;
interface IGeneration {
  id: string;
  name: string;
  userID?: string;
}

const getParentKey = (key: string, tree: IUnitUser[]): string | undefined => {
  let parentKey;
  tree.forEach((node) => {
    if (node.childs) {
      if (node.childs.some((item) => item.id === key)) {
        parentKey = node.id;
      } else if (getParentKey(key, node.childs)) {
        parentKey = getParentKey(key, node.childs);
      }
    }
  });
  return parentKey;
};

@Component({})
export default class UserGroupModal extends Vue {
  @Prop({ default: [] }) public readonly allUserList!: IUnitUser[];
  public apiBaseUrl: string = config.apiBaseUrl;
  public token: string = storage.localGet("version_user_info").token;
  public visible: boolean = false;
  public form: any;
  public formItemLayout: object = {
    labelCol: { span: 3 },
    wrapperCol: { span: 20 },
  };
  public replaceFields = {
    key: "id",
    children: "childs",
    title: "name",
  };
  public checkedKeys: string[] = [];
  public userGroupId: string = "";
  public expandedKeys: string[] = [];
  public searchValue: string = "";
  public autoExpandParent: boolean = false;
  public dataList: IGeneration[] = [];

  public beforeCreate(): void {
    this.form = this.$form.createForm(this);
  }

  public onChangeVisible(userGroup?: { id: string; name: string }) {
    this.visible = !this.visible;
    if (this.visible) {
      userGroup &&
        this.$nextTick(() => {
          this.initUserGroupForm(userGroup);
        });
      this.generateList(this.allUserList);
    } else {
      this.form.resetFields();
      this.checkedKeys = [];
      this.userGroupId = "";
      this.expandedKeys = [];
      this.searchValue = "";
      this.autoExpandParent = false;
      this.dataList = [];
    }
  }
  public async initUserGroupForm(userGroup: { id: string; name: string }) {
    this.userGroupId = userGroup.id;
    this.form.setFieldsValue({ name: userGroup.name });
    const response = await getItemUserGroupUserList(userGroup.id);
    if (!response.status) {
      this.$message.error(response.message);
      return;
    }
    const userIds = response.data.categories.map((item) => item.userID);
    this.checkedKeys = userIds;
  }
  public onCheck(checkedKeys: string[]) {
    this.checkedKeys = checkedKeys.filter((key) => {
      const item = this.dataList.find((item) => item.id === key);
      if (item && item.userID) {
        return true;
      }
    });
  }
  public onSubmit() {
    this.form.validateFields(async (err: object, values: { name: string }) => {
      if (!err) {
        try {
          const itemId = this.$route.query.id as string;
          const userIds = this.checkedKeys.filter((key) => {
            const data = this.dataList.find((item) => item.id === key);
            if (data && data.userID) {
              return true;
            }
          });
          const addRequest = {
            itemId,
            name: values.name,
            itemGroupUser: userIds,
          };
          const updateRequest = {
            id: this.userGroupId,
            itemId,
            name: values.name,
            itemGroupUser: userIds,
          };
          let response;
          if (this.userGroupId) {
            response = await updateItemUserGroup(updateRequest);
          } else {
            response = await addItemUserGroup(addRequest);
          }
          if (!response.status) {
            this.$message.error(response.message);
            return;
          }
          this.$message.success("Success!");
          this.$emit("refresh");
          this.onChangeVisible();
        } catch (error) {
          this.$message.error(error as string);
        }
      }
    });
  }
  public onExpand(expandedKeys: string[]) {
    this.expandedKeys = expandedKeys;
    this.autoExpandParent = false;
  }
  public onSearch(e: { target: { value: string } }) {
    const value = e.target.value;
    const expandedKeys = this.dataList
      .map((item) => {
        if (item.name.toUpperCase().includes(value.toUpperCase())) {
          return getParentKey(item.id, this.allUserList);
        }
        return null;
      })
      .filter((item, i, self) => item && self.indexOf(item) === i);
    Object.assign(this, {
      expandedKeys,
      searchValue: value,
      autoExpandParent: true,
    });
  }
  public generateList(data: IUnitUser[]) {
    data.forEach((node) => {
      const key = node.id;
      this.dataList.push({
        id: key as string,
        name: node.name,
        userID: node.userID,
      });
      if (node.childs) {
        this.generateList(node.childs);
      }
    });
  }
  public onTagClose(id: string) {
    this.checkedKeys = this.checkedKeys.filter((key) => key !== id);
  }

  public get checkedUserList(): IGeneration[] {
    const checkedUsers: IGeneration[] = [];
    this.checkedKeys.forEach((key) => {
      const item = this.dataList.find((data) => key === data.id);
      if (item && item.userID) {
        checkedUsers.push(item);
      }
    });
    return checkedUsers;
  }

  public userGroupUserUploadHandleChange(info: any) {
    if (info.file?.response) {
      if (info.file?.response?.status) {
        this.checkedKeys = info.file?.response.groupUserStaff.map(
          (item: any) => item.userID
        );
      } else {
        this.$message.error(info.file?.response?.message);
      }
    }

    console.log(info);
  }
}
