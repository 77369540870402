var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"app-detail"},[(_vm.appItem)?_c('a-card',[_c('div',{attrs:{"slot":"title","data-test":"title"},slot:"title"},[_c('span',{staticClass:"app-name"},[_vm._v(_vm._s(_vm.appItem.items_name))]),_c('a-icon',{staticStyle:{"font-size":"20px","margin-left":"20px"},attrs:{"type":_vm.appItem.type === '0'
            ? 'apple'
            : _vm.appItem.type === '1'
            ? 'android'
            : _vm.appItem.type === '2'
            ? 'laptop'
            : 'windows'}}),_c('span',{staticClass:"app-description"},[_vm._v(_vm._s(_vm.appItem.description))])],1),_c('div',{attrs:{"slot":"extra"},slot:"extra"},[_c('a-button',{staticStyle:{"margin-right":"20px"},attrs:{"data-test":"shortUrlBtn","type":"primary","shape":"circle","icon":"setting"},on:{"click":_vm.showShortUrl}}),_c('a-button',{attrs:{"data-test":"updateBtn","type":"primary","shape":"round","icon":"upload"},on:{"click":_vm.showUpdate}},[_vm._v(" Update new version ")])],1),_c('div',{staticClass:"app-detail-bottom"},[_c('div',{staticClass:"app-info"},[_c('div',[_c('p',{staticClass:"title"},[_vm._v("Address")]),_c('p',{staticClass:"content"},[_vm._v(" "+_vm._s(`${_vm.apiBaseUrl}/upgrade?id=${_vm.$route.query.id}`)+" ")])]),(_vm.newVersionDetail && _vm.newVersionDetail.type === '0')?_c('div',{staticStyle:{"margin-left":"30px"}},[_c('p',{staticClass:"title"},[_vm._v(" Expiration Date "),(_vm.isExpired)?_c('a-tag',{attrs:{"color":"#ff4d4f"}},[_vm._v("Expired")]):_vm._e()],1),_c('p',{staticClass:"content"},[_vm._v(" "+_vm._s(_vm.convertTime(_vm.newVersionDetail.ExpirationDate))+" ")])]):_vm._e()]),_c('div',[_c('div',{staticClass:"qrcode",on:{"click":_vm.goToPreview}},[(_vm.newVersionDetail && _vm.preViewUrl)?_c('vue-qr',{ref:"vueQr",attrs:{"text":_vm.preViewUrl,"logoSrc":_vm.newVersionDetail.icon,"size":150,"margin":0}}):_vm._e()],1)])])]):_vm._e(),_c('ModalUpload',{ref:"ModalUploadRef",attrs:{"userGroupList":_vm.userGroupList},on:{"refresh":_vm.refresh}}),_c('ModalShortUrl',{ref:"ModalShortUrlRef",on:{"refresh":_vm.refresh}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }